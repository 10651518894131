:root {
  @include MQ-Above(medium) {
    --cta__learn__inner__max-width: 50vw;
    --cta__questions__image__height: 120%;
    --cta__questions__image__width: 30%;
    --cta__questions__image__left: 10%;
    --cta__learn__margin: 2rem 0 0 0;
  }

  @include MQ-Between(small, large) {
    --cta__questions__image__height: 80%;
    --cta__learn__bg__size: 25%;
  }

  @include MQ-Between(large, jumbo) {
    --cta__learn__bg__size: 22%;
  }

  @include MQ-Above(jumbo) {
    --cta__learn__bg__size: contain;
  }
}

.cta {
  text-align: center;

  p {
    max-width: 23rem;
    margin: 0.625rem auto 0 auto;
  }

  .primary-cta {
    margin-top: 1rem;
  }

  &--learn {
    background-image: url("~/public/assets/img/geek_female.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: var(--cta__learn__bg__size, 30%);
    background-color: var(--tokens__colors__orange);
    margin: var(--cta__learn__margin, 0);

    &__inner {
      padding: 2rem;

      @include Hover-Supported() {
        &:hover {
          .primary-cta {
            --primary-cta__background-color: var(--tokens__colors__dark-purple);
          }
        }
      }
    }
  }

  &--questions {
    padding: 2rem;
    background: var(--tokens__colors__light-grey);
    display: block;

    &__inner {
      padding: 2rem;
      background-color: white;
      width: 100%;
      max-width: var(--tokens__max-width);
      margin: auto;
      position: relative;
      border: 5px solid var(--tokens__colors__purple);
      text-decoration: none;

      @include Hover-Supported() {
        &:hover {
          .primary-cta {
            --primary-cta__background-color: var(--tokens__colors__dark-purple);
          }
        }
      }

      &__image {
        position: absolute;
        left: var(--cta__questions__image__left, 0);
        bottom: 0;
        height: var(--cta__questions__image__height, 45%);
        width: var(--cta__questions__image__width, 45%);

        img {
          -o-object-fit: contain;
             object-fit: contain;
          height: 100%;
          width: 100%;
          -o-object-position: bottom;
             object-position: bottom;
        }
      }
    }
  }
}
