:root {
  @include MQ-Above(medium) {
    --react-tabs__tab__font-size: 0.75rem;
  }
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-item__title {
    font-size: 0.625rem;
    text-transform: uppercase;
    color: var(--tokens__colors__purple);
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  &__tab-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
  }

  &__tab {
    position: relative;
    list-style: none;
    cursor: pointer;
    padding: 1.25rem;
    text-transform: uppercase;
    font-size: var(--react-tabs__tab__font-size, 0.55rem);
    letter-spacing: 1px;
    font-weight: 700;
    background: var(--tokens__colors__light-grey);
    border: 1px solid var(--tokens__colors__medium-grey);
    display: flex;
    align-items: center;
    justify-content: center;

    @include MQ-Below(small) {
      padding: .9rem .6rem;
      line-height: 1.4;
    }

    &::after {
      content: "";
      width: 100%;
      height: 5px;
      background: var(--tokens__colors__purple);
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transition: transform 0.15s ease-in;
      transform: scaleX(0);
      transform-origin: center center;
    }

    &:hover::after {
      transform: scaleX(1);
    }

    &--selected {
      background: var(--tokens__colors__light-purple);
      color: var(--tokens__colors__purple);

      &::after {
        transform: scaleX(1);
      }
    }
  }
}
