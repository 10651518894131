:root {
  @include MQ-Above(medium) {
    --site-mobile-nav__visibility: hidden;
  }
}

.site-mobile-nav {
  background: rgba(255, 255, 255, 0.9);
  z-index: 30;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  visibility: var(--site-mobile-nav__visibility, visible);
  transition: opacity 0.3s ease-in;

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
  }

  ul {
    box-shadow: 0 8px 8px rgba(62, 27, 96, 0.19);
    display: flex;
    flex-direction: column;

    li {
      width: 100%;
      text-align: center;
      border-top: 1px solid #e5e5e5;
      background: white;

      &.language-selector {
        order: 1;
        padding-bottom: 0;

        .language-selector {
          padding-top: 10px;

          button {
            &.dropdown-toggle {
              min-width: auto;
            }
          }
        }
      }

      &.explore-prizm {
        order: 2;
      }

      &.how-prizm-helps {
        order: 3;
      }

      &.environics-analytics {
        order: 4;
      }

      &.contact-us {
        order: 5;
      }

      a {
        padding: 1.25rem;
        width: 100%;
        height: 100%;
        text-decoration: none;

        &:not(.primary-cta):focus-visible {
          outline-color: var(--primary-cta__background-color);
          outline-offset: -2px;
        }

        &.primary-cta:focus-visible {
          outline-offset: -6px;
          outline-color: white;
        }
      }
    }
  }
}