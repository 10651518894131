.site-header {
  background: white;
  position: relative;
  z-index: 15;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;

  &__logo {
    width: 18.75rem;

    img {
      width: 100%;
    }

    &:focus-visible {
      outline-offset: -2px;
    }
  }

  nav {
    display: flex;
  }
}
