:root {
  --segment-thumbnails__item-size: 6.2rem;
  --segment-thumbnails__item-size--active: 1.1;
  --segment-thumbnails__container-height: 9em;

  @include MQ-Above(medium) {
    --segment-thumbnails__container-height: 13rem;
    --segment-thumbnails__item-size: 10rem;
  }
}

.segment-thumbnails {
  &__container {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: var(--segment-thumbnails__container-height);
    display: flex;
    align-items: center;
    background: var(--tokens__colors__light-grey);
  }

  &__nav {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    z-index: 2;
    align-items: center;
    padding: 0 20px;
    margin: auto;

    &__item {
      width: var(--segment-thumbnails__item-size);
      min-height: var(--segment-thumbnails__item-size);
      margin: 0 10px;
      transform-origin: center center;
      transition: box-shadow 0.2s ease-in, transform 0.1s ease-in;
      box-shadow: 0 0.1875rem 0.625rem rgba(88, 44, 131, 0.33);

      &__inner {
        display: grid;
        transform-origin: center center;
        transition: transform 0.1s ease-in;
        min-height: var(--segment-thumbnails__item-size);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      &:hover {
        transform: scale(1.03);
        box-shadow: 0 0.3rem 0.8rem rgba(88, 44, 131, 0.5);
      }

      img,
      &__content {
        grid-column: 1;
        grid-row: 1;
      }

      &__content {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        background: linear-gradient(180deg, rgba(88, 44, 131, 0) 20%, var(--tokens__colors__purple) 100%);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__title {
          text-align: center;
          padding: 0 10px 15px 10px;
          font-size: 0.75rem;
          word-break: break-word;
          font-weight: 600;
        }

        span {
          background: var(--tokens__colors__purple);
          display: flex;
          height: 20px;
          width: 20px;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 600;
        }
      }

      &--active {
        width: calc(var(--segment-thumbnails__item-size) * var(--segment-thumbnails__item-size--active));
        box-shadow: 0 6px 10px rgba(49, 4, 94, 0.6);
        outline: 4px solid var(--tokens__colors__purple);
        z-index: 5;
        animation: animation__pop 0.25s 1 ease-in;

        .segment-thumbnails__nav__item__inner {
          transform: translateX(calc(var(--segment-thumbnails__item-size) * calc(var(--segment-thumbnails__item-size--active) - 1)) / 2) scale(var(--segment-thumbnails__item-size--active));
        }

        &:focus-visible {
          outline: 4px dashed var(--tokens__colors__purple);

          &:hover {
            box-shadow: 0 6px 10px rgba(49, 4, 94, 0.6);
            transform: scale(1.025);
          }
        }

        body:not(.user-is-tabbing) &:focus-visible {
          outline: 4px solid var(--tokens__colors__purple);

        }
      }
    }
  }
}

@keyframes animation__pop {
  0% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.15);
  }
}
