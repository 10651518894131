:root {
  @include MQ-Above(medium) {
    --filter-stats__stat-display: inline-block;
    --filter-stats__columns: repeat(4, 1fr);
    --filter-stats__button-columns: auto;
  }
}

.filter-stats {
  display: flex;
  justify-content: center;
  padding: 1rem;
  max-width: 1000px;
  margin: 0 auto;
  background: white;

  @include MQ-Below(medium) {
    flex-direction: column;
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    align-items: center;
    width: 100%;
    max-width: 900px;
    flex-basis: 70%;

    @include MQ-Below(medium) {
      flex-basis: 100%;
    }

    li {
      text-align: center;
      font-size: 0.9rem;

      b {
        margin-right: 5px;
        display: var(--filter-stats__stat-display, block);

        .loader {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    margin: 0 auto;

    @include MQ-Below(medium) {
      flex-basis: 100%;
      margin-top: 1rem;
      width: 80%;
    }
  }

  &__icon {
    position: relative;
    width: 8px;
    height: 2px;
    display: inline-flex;
    margin: 0 10px 0 0;
    background: currentColor;

    &::after,
    &::before {
      content: "";
      position: absolute;
      display: block;
      background: currentColor;
      height: 2px;
    }

    &::before {
      width: 12px;
      top: -4px;
      left: -2px;
    }

    &::after {
      width: 4px;
      top: 4px;
      left: 2px;
    }
  }
}
