html {
  font-size: 18px; // This establishes what 1rem is
  scroll-behavior: smooth;
}

body {
  font-family: var(--tokens__font-family__lato);
  font-weight: 400;
  background: white;
  color: var(--tokens__colors__darkest-grey);
  position: relative;

  // Disable scrolling when mobile nav is open
  &[data-site-mobile-nav-active="true"] {
    height: 100%;
    overflow: hidden;
  }
}

.skip-to-main {
  background: black;
  color: white;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 100;
  padding: 0.625rem;
  transform: var(--skip-to-main__transform, translateX(-1000px));
  text-decoration: none;
}

.skip-to-main:focus-visible,
.skip-to-main:active {
  --skip-to-main__transform: none;
}

button:disabled {
  opacity: 0.5;

  &:hover {
    opacity: 0.5 !important;
  }
}
