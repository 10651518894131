:root {
  @include MQ-Above(medium) {
    --search__inner__padding: 4% 8%;
    --search__postal-lookup__padding: 10px;
  }
}

.search {
  background: var(--tokens__colors__purple);
  background-image: url("~/public/assets/img/bg_search_left.svg"), url("~/public/assets/img/bg_search_right.svg");
  background-position: left bottom, right top;
  background-repeat: no-repeat;
  color: white;
  margin-top: 2rem;

  &__inner {
    align-items: center;
    max-width: var(--tokens__max-width);
    margin: auto;
    position: relative;
    padding: var(--search__inner__padding, 8%);
    text-align: center;

    &__info {
      font-size: 1.3rem;
      line-height: 1.2;
    }
  }

  &__error {
    color: var(--tokens__colors__orange);
    margin-top: 1.5rem;
    font-size: 1.1rem;
  }

  .postal-lookup {
    &__input {
      box-shadow: 0 8px 15px rgba(46, 0, 91, 0.4);
      padding: var(--search__postal-lookup__padding, 5px);

      &::after {
        --postal-lookup__hover-color: var(--tokens__colors__orange);

        height: 4px;
      }
    }

    .primary-cta {
      --primary-cta__background-color: black;

      @include Hover-Supported() {
        &:hover {
          background: var(--tokens__colors__purple);
        }
      }
    }
  }
}
