/* SCSS variables for things for which that makes sense */
$Colors__Purple: #582c83;
$Colors__DarkPurple: #351357;
$Colors__Orange: #ffcb40;
$Colors__LightPurple: #e3dbea;
$Colors__LightGrey: #f5f5f5;
$Colors__MediumGrey: #e1e1e1;
$Colors__DarkGrey: #606060;
$Layer__Width--Narrow: 41.25rem;

:root {
  /* Primary Colors */
  --tokens__colors__purple: #{$Colors__Purple};
  --tokens__colors__dark-purple: #{$Colors__DarkPurple};
  --tokens__colors__light-purple: #{$Colors__LightPurple};
  --tokens__colors__orange: #{$Colors__Orange};

  /* Utility Colors */
  --tokens__colors__light-grey: #{$Colors__LightGrey};
  --tokens__colors__medium-grey: #{$Colors__MediumGrey};
  --tokens__colors__dark-grey: #{$Colors__DarkGrey};
  --tokens__colors__white--faded-50: fade(white, 50%);
  --tokens__colors__black--faded-50: fade(black, 50%);

  /* Font Families */
  --tokens__font-family__lato: "Lato", sans-serif;

  /* Transition */
  --tokens__transition: all 0.15s;

  /* Sizing */
  --tokens__max-width: 1350px;
  --tokens__header__max-width: 55rem;

  /* Shadows */
  --tokens__box-shadow: 0 1px 4px rgba(88, 44, 131, 0.36);
}
