//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

// A map of breakpoints.
$Breakpoints: (
  small: 450px,
  medium: 768px,
  large: 1158px,
  jumbo: 1920px,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include MQ-Above(Small) {}
@mixin MQ-Above($Breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($Breakpoints, $Breakpoint) {
    // Get the breakpoint value.
    $Breakpoint-value: map-get($Breakpoints, $Breakpoint);

    // Write the media query.
    @media (min-width: $Breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$Breakpoint}.";
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include MQ-Below(sm) {}
@mixin MQ-Below($Breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($Breakpoints, $Breakpoint) {
    // Get the breakpoint value.
    $Breakpoint-value: map-get($Breakpoints, $Breakpoint);

    // Write the media query.
    @media (max-width: ($Breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn "Invalid breakpoint: #{$Breakpoint}.";
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include MQ-Between(sm, md) {}
@mixin MQ-Between($Lower, $Upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($Breakpoints, $Lower) and map-has-key($Breakpoints, $Upper) {
    // Get the lower and upper breakpoints.
    $Lower-Breakpoint: map-get($Breakpoints, $Lower);
    $Upper-Breakpoint: map-get($Breakpoints, $Upper);

    // Write the media query.
    @media (min-width: $Lower-Breakpoint) and (max-width: ($Upper-Breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($Breakpoints, $Lower) == false) {
      // Log a warning.
      @warn "Your lower breakpoint was invalid: #{$Lower}.";
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($Breakpoints, $Upper) == false) {
      // Log a warning.
      @warn "Your upper breakpoint was invalid: #{$Upper}.";
    }
  }
}

//
//  SHOW ON HOVER SUPPORTED DEVICES
//––––––––––––––––––––––––––––––––––––––––––––––––––

@mixin Hover-Supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
