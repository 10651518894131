select {
  appearance: none;
  box-shadow: none;
  border: 0 !important;
  color: #333;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0;
  font-size: 16px;
  position: relative;
  width: 100%;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.19082 5.24014L12.2943 0.341704C12.4203 0.218657 12.6283 0.221587 12.7572 0.347563L13.6537 1.22354C13.7826 1.34952 13.7856 1.55459 13.6596 1.67764L7.42813 7.66006C7.36368 7.72452 7.27579 7.75381 7.19082 7.74795C7.10293 7.75088 7.01797 7.72159 6.95352 7.66006L0.722074 1.67764C0.596098 1.55459 0.599027 1.34952 0.727934 1.22354L1.62442 0.347563C1.75332 0.221587 1.96133 0.218657 2.08731 0.341704L7.19082 5.24014Z' fill='%23582C83'/%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.75rem top 50%, 0 0;
  background-size: 0.8rem auto, 100%;
  background-color: white;
}

select::-ms-expand {
  display: none;
}

.select-group {
  flex: 1;
  position: relative;
  width: 100%;

  select {
    position: relative;
  }

  &__clear {
    align-items: center;
    background: transparent;
    display: none;
    height: 100%;
    padding: 0 6px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &--active {
      display: flex;
    }

    &:hover {
      & > svg {
        transform: scale(1.1);
      }
    }

    & > svg {
      height: 24px;
      pointer-events: none;
      width: 24px;
    }
  }
}
