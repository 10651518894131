:root {
  @include MQ-Above(medium) {
    --site-footer__flex-direction: row;
    --site-footer__nav-margin: 0 1.25rem;
    --site-footer__padding: 0;
  }
}

.site-footer {
  background: white;
  position: relative;
  z-index: 15;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  flex-direction: var(--site-footer__flex-direction, column);
  align-items: center;
  margin: auto;
  border-top: 1px solid #e5e5e5;
  padding: var(--site-footer__padding, 1rem);
  gap: 10px;

  &__logo {
    width: 18.75rem;
    max-width: 100%;

    img {
      width: 100%;
    }

    &:focus-visible {
      outline-offset: -2px;
    }
  }

  svg {
    width: auto;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 1.25rem;
    margin: var(--site-footer__nav-margin, 1rem 0 0);

    li {
      padding: 0.5rem;
      line-height: 1.3;

      a {
        text-underline-offset: 3px;

        &:hover {
          opacity: 1;
          color: var(--tokens__colors__purple);
        }
      }

      button {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

  &__legal {
    font-size: 0.75rem;
    padding: 0 1rem;
    text-align: center;
  }
}

@media only screen and (max-width: 1600px) and (min-width: 767px){
  .site-footer {
    ul {
      margin: 0;
      li {
        padding: 0.5rem 0.3rem;
        line-height: 1;
        font-size: 15px;
      }
    }
  }
}