.esri {
  &-attribution {
    background-color: transparent;
    color: white;

    &__sources {
      display: none;
    }
  }

  &-view-surface--inset-outline:focus::after {
    outline: none !important;
  }
}
