.modal {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;
    background: white;
    box-shadow: 0 8px 22px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translateY(-50%) translateX(-50%);
    width: 40rem;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: auto;

    p {
      margin: 0.5rem 0 1rem;
    }

    &__close {
      width: 22px;
      height: 22px;
      border: 2px solid transparent;
      border-radius: 40px;
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--error {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      padding: 1rem;
      display: block;
      width: 100%;
    }

    &--success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      padding: 1rem;
      display: block;
      width: 100%;
    }

    &--interstitial {
      background: var(--tokens__colors__purple);
      color: white;

      .modal__inner__close {
        color: white;
      }

      .primary-cta {
        --primary-cta__background-color: var(--tokens__colors__orange);

        color: black;
      }
    }

    &--limit {
      background: var(--tokens__colors__orange);
    }

    &--login {
      border-top: 5px solid var(--tokens__colors__purple);

      p {
        text-transform: uppercase;
        font-size: 0.7rem;
      }

      label {
        font-size: 0.75rem;
        margin: 0.5rem 0;
      }

      input[type="password"] {
        border: 1px solid var(--tokens__colors__purple);
        margin-bottom: 1rem;
      }
    }

    &--tos-link {
      color: #0000EE;
    }
  }
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 30;
  padding: 0 1rem;
}
