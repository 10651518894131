:root {
  @include MQ-Above(medium) {
    --site-nav__margin-right: 0.625rem;
  }
}

.site-nav {
  display: flex;
  min-height: 100%;
  margin-right: var(--site-nav__margin-right, 0);

  li {
    margin-left: 2rem;
    font-weight: 300;
    height: 100%;
    display: flex;
    align-items: center;
    
    a {
      text-underline-offset: 3px;

      &:not(.primary-cta):focus-visible {
        outline-color: var(--primary-cta__background-color);
        outline-offset: 8px;
      }
    }
  }

  &__menu-button {
    border-left: 1px solid #e5e5e5;
    margin-left: auto;
    width: 61px;
    width: clamp(44px, 12vw, 61px);

    &:focus-visible {
      outline-offset: -2px;
    }
  }
}
