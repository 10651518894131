.site-mobile-nav .language-selector ul li {
  border-top: none;
}

.language-selector {
  border-color: #fff;
  display: inline-block;
  position: relative;
  font-family: var(--tokens__font-family__lato);
  font-size: 18px;
  font-weight: 300;
  color: var(--tokens__colors__darkest-grey);
  padding-bottom: 10px;

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  img {
    border-radius: 4px;
    width: 20px;
    margin-right: 5px;
  }

  button {
    margin-bottom: 0;
    display: inline-block;
    box-shadow: 0 0 1px transparent;
    background-color: transparent;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 6px 12px 6px 12px;
    line-height: 1.5;
    border-radius: 5px;
    user-select: none;

    &.dropdown-toggle {
      margin-top: auto;
      border: 2px solid #e6e6e6;
      min-width: 284px;
      text-align: left;
    }

    .select-down-arrow {
      margin-top: -4px;
      margin-left: 4px;
      vertical-align: top;
      font-weight: normal;
      font-style: normal;
      font-size: 24px;
      line-height: 1;
      letter-spacing: normal;
      text-transform: none;
      display: inline-block;
      white-space: nowrap;
      word-wrap: normal;
      direction: ltr;
    }

    img {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      margin-right: 7px;
      vertical-align: bottom;
    }
  }

  .dropdown-menu {
    list-style-type: none;
    position: absolute;
    top: calc(100% - 12px);
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 210px;
    width: 105%;
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
    background-color: #fff;
    border: 2px solid #e6e6e6;
    border-radius: 0 5px 5px 5px;
    text-align: left;
  }

  li {
    display: block;
    margin-left: 0;
    padding: 6px;

    &:hover {
      background-color: #ebebeb;
    }
  }

  .block {
    display: block;
  }
}

@media (min-width: 768px) {

  .language-selector {
    padding-bottom: 0;

    .dropdown-menu {
      top: calc(100% - 2px);
    }

    button {

      &.dropdown-toggle {
        min-width: 96px;
      }
    }
  }
}

@media (min-width: 1024px) {

  .language-selector {
    transition: .5s all ease;
  }
}