:root {
  @include MQ-Above(medium) {
    --segment-details__slide--width: 80vw;
    --segment-details__controls__button__padding: 0 20px;
    --segment-details__slide--height: 650px;
    --segment-details__button-arrow__scale: scale(1);
    --segment-details__grid-gap: 2rem;
    --segment-details-font-size: 0.9rem;
  }

  @include MQ-Above(large) {
    --segment-details__slide--height: 550px;
  }
}

.segment-details {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;

  p {
    font-size: var(--segment-details-font-size, 0.8rem);
  }

  h2 {
    color: var(--tokens__colors__purple);
    display: inline-flex;
  }

  &__title {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  &__number {
    @extend .title--subtitle;

    display: inline-flex;
    font-size: var(--title__font-size);
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    padding: 0.3125rem;
    background: var(--tokens__colors__purple);
    margin-right: 0.625rem;
  }

  &__represents {
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--tokens__colors__dark-grey);
    margin-bottom: 0.5rem;
  }

  &__section {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  &__slide {
    flex: 0 0 var(--tokens__max-width);
    max-width: var(--segment-details__slide--width, 95vw);
    padding: 2rem;
    text-align: center;
    opacity: 0.3;
    height: auto;
    position: relative;
    z-index: 1;

    &:not(.segment-details__slide--active) {
      overflow: hidden;
      height: var(--segment-details__slide--height, 0);

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }
    }

    &[aria-hidden="true"] {
      display: none;
    }

    > div {
      margin-bottom: 1.5rem;

      p {
        em {
          font-style: normal;
          font-weight: 700;
        }
      }
    }

    &--active {
      opacity: 1;
      -webkit-animation: animation__fadeUp 2s 1 cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      animation: animation__fadeUp 2s 1 cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &__who {
      text-align: left;
      display: grid;
      grid-template-columns: var(--grid__columns--two);
      grid-gap: 2rem;
      font-size: var(--segment-details-font-size, 0.8rem);

      &__text {
        strong {
          font-weight: 600;
        }
      }

      button {
        color: var(--tokens__colors__purple);
        text-decoration: underline;
        margin-top: 0.5rem;
      }

      ul {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: var(--grid__columns--three);
      }

      p {
        word-break: break-word;
      }
    }

    &__think {
      ul {
        display: grid;
        grid-gap: 2rem;
      }
    }

    &__live {
      ul {
        display: grid;
        grid-template-columns: var(--grid__columns--three);
        grid-gap: var(--segment-details__grid-gap, 2rem 1rem);

        img {
          max-height: 30px;
          margin-bottom: 0.5rem;
        }

        .point-title {
          display: block;
          font-weight: 700;
        }

        p {
          line-height: 1.2;
          font-size: var(--segment-details-font-size, 0.8rem);
          word-break: break-word;
        }
      }
    }
  }

  &__short-description {
    font-style: italic;

    &::after {
      content: "";
      display: block;
      width: 50px;
      height: 1px;
      background: black;
      margin: 10px auto;
    }
  }

  &__controls {
    margin-top: 2.5rem;
    position: absolute;
    top: 0;
    z-index: 5;

    .button-arrow {
      transform: var(--segment-details__button-arrow__scale, scale(0.5));
    }

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--left,
    &--right {
      @include Hover-Supported() {
        &:hover {
          --button-arrow__background: var(--tokens__colors__purple);
          --button-arrow__color: white;
        }
      }
    }

    @include MQ-Above(medium) {
      &--left {
        background: linear-gradient(-269.33deg, #fff 2.55%, rgba(255, 255, 255, 0.2) 52.05%, rgba(255, 255, 255, 0) 98.56%);
      }

      &--right {
        background: linear-gradient(269.33deg, #fff 2.55%, rgba(255, 255, 255, 0.2) 52.05%, rgba(255, 255, 255, 0) 98.56%);
      }
    }

    &__button {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;
      padding: var(--segment-details__controls__button__padding, 0);

      @include MQ-Above(medium) {
        padding-bottom: 20px;
        padding-top: 20px;
      }

      &:focus-visible {
        outline-offset: -2px;
      }
    }
  }
}

@-webkit-keyframes animation__fadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animation__fadeUp {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
