:root {
  @include MQ-Above(medium) {
    --marketing__columns: repeat(2, 1fr);
    --marketing__gap: 5rem;
    --marketing__padding: 6rem 2rem 3rem 2rem;
  }
}

.marketing {
  padding: var(--marketing__padding, 3rem 2rem 1rem 2rem);
  background: var(--tokens__colors__light-grey);

  &__inner {
    max-width: var(--tokens__max-width);
    margin: auto;

    &__header {
      text-align: center;
      margin-bottom: 3rem;
    }

    img {
      margin-bottom: 1rem;
      width: 100%;
    }

    .title {
      margin-bottom: 0.65rem;
    }

    &__sections {
      display: grid;
      grid-template-columns: var(--marketing__columns, auto);
      grid-gap: var(--marketing__gap, 3rem);

      @include MQ-Above(medium) {
        > article {
          &:nth-child(2) {
            margin-top: 45%;
          }

          &:nth-child(3) {
            margin-top: -40%;
          }

          &:nth-child(4) {
            margin-top: 15%;
          }
        }
      }

      article {
        p + p {
          margin-top: 1rem;
        }

        p {
          font-weight: 300;
        }

        ul {
          margin: 1rem 0 1rem 1rem;

          li {
            list-style: disc;
            display: list-item;
            margin-bottom: 1rem;
            font-weight: 300;
            line-height: 1.3;
          }
        }

        .primary-cta {
          margin-top: 1rem;
        }
      }
    }
  }
}
