:root {
  --postal-lookup__hover-color: var(--tokens__colors__purple);

  @include MQ-Above(medium) {
    --postal-lookup__font-size: 1rem;
  }
}

.postal-lookup {
  position: relative;
  font-size: var(--postal-lookup__font-size, 0.9rem);

  .loader {
    width: 40px;
    height: 40px;
    margin: auto 10px auto 0;
  }

  &__input {
    display: flex;
    background: white;
    border: 1px solid var(--tokens__colors__purple);
    margin-top: 1.5rem;
    box-shadow: 2px 6px 8px rgba(88, 44, 131, 0.1);
    position: relative;

    & label {
      position: absolute;
      top: 100%;
      left: -1px;
      margin-top: .5rem;
      font-size: .85rem;
      font-weight: 300;
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: var(--postal-lookup__hover-color);
      margin-top: -1px;
      transform: scaleX(0);
      transition: transform 0.2s ease-in;
    }

    @include Hover-Supported {
      &:hover {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }

  &__multi {
    position: absolute;
    z-index: 40;
    top: 100%;
    width: 100%;

    > p {
      background: var(--tokens__colors__orange);
      color: black;
      font-weight: 700;
      padding: 1rem;
      text-transform: uppercase;
      text-align: center;
      font-size: 0.7rem;
      letter-spacing: 1px;
    }

    &__inner {
      background: white;
      max-height: 40vh;
      overflow-y: auto;
    }

    ul {
      li {
        border-bottom: 1px solid var(--tokens__colors__light-grey);
        font-size: 0.8rem;

        &:hover {
          background: var(--tokens__colors__light-grey);
        }

        button {
          text-align: left;
          width: 100%;
          padding: 1rem;
        }
      }
    }
  }
}
