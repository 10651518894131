:root {
  --hero__map__point__size: 120px;

  @include MQ-Above(small){
    --hero__map__point__size: 160px;
  }
  
  @include MQ-Above(medium) {
    --hero__min-height: 60vh;
    --hero__inner__padding: 4% 8%;
    --hero__intro__text-align: left;
    --hero__columns: 0.7fr 1fr;
    --hero__map__height: 70vh;
  }

  @include MQ-Above(large){
    --hero__map__point__size: 200px;
  }
}

.hero {
  min-height: var(--hero__min-height, auto);
  display: flex;
  align-items: center;

  &__inner {
    display: grid;
    grid-template-columns: var(--hero__columns, 1fr);
    align-items: center;
    max-width: 125rem;
    margin: auto;
    position: relative;
  }

  &__intro {
    text-align: var(--hero__intro__text-align, center);

    .title--secondary {
      color: var(--tokens__colors__purple);
    }

    padding: var(--hero__inner__padding, 8% 8% 8% 8%);

    @include MQ-Below(small) {
      padding: var(--hero__inner__padding, 8% 8% 14% 8%);
    }

    &__error {
      color: #c62a05;
      margin-top: 1.5rem;
      font-size: 0.85rem;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.15s ease-in;

      &--active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__map {
    position: relative;

    &__thumbnails {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;

      & .btn {
        &:nth-child(1n + 1) {
          top: 33%;
          left: 30%;
        }

        &:nth-child(2n + 1) {
          top: 81%;
          left: 34%;
        }

        &:nth-child(3n + 1) {
          top: 53%;
          left: 75%;
        }
      }

      @include MQ-Above(small) {
        .btn {
          &:nth-child(1n + 1) {
            top: 36%;
            left: 25%;
          }

          &:nth-child(2n + 1) {
            top: 73%;
            left: 44%;
          }

          &:nth-child(3n + 1) {
            top: 62%;
            left: 74%;
          }
        }
      }
    }

    &__point {
      background: var(--tokens__colors__purple);
      position: absolute;
      margin: calc(-1 * var(--hero__map__point__size, 120px) / 2);
      width: var(--hero__map__point__size, 120px);
      height: var(--hero__map__point__size, 120px);
      transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      will-change: transform;
      z-index: 1;
      transform-origin: center center;

      a {
        display: block;
      }

      &__info {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background: linear-gradient(359.51deg, #582c83 7%, rgba(88, 44, 131, 0) 46.1%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        color: white;
        padding: 1rem;
        box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0), 0 0.625rem 0.625rem rgba(68, 30, 106, 0.26);
        transition: all 0.3s ease-in;

        &__title {
          position: relative;
          z-index: 5;
          text-align: center;
          display: block;
          padding: 0 10px;
          min-width: var(--hero__map__point__size, 120px);
          font-weight: 600;
          font-size: .675rem;

          @include MQ-Above(small){
            font-size: .875rem;
          }
        }

        img {
          z-index: 1;
        }
      }

      &--active {
        transform: scale(1);
        opacity: 1;
        z-index: 10;
      }
    }
  }

  &__scroll-to {
    font-size: 16px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-table;
    margin: auto;
    padding-bottom: 8px;
    text-align: center;
    color: var(--tokens__colors__purple);
    border: 2px solid transparent;
    border-radius: 100px;
    text-decoration: none;

    &::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      left: 49.5%;
      top: 50%;
      margin-top: 10px;
    }
  }

  .postal-lookup__multi {
    display: none;
  }
}
