:root {
  @include MQ-Above(medium) {
    --input__padding: 1rem;
  }
}

input[type="email"],
input[type="text"],
input[type="password"] {
  display: block;
  width: 100%;
  border: none;
  padding: var(--input__padding, 0.6rem);
  background: white;
  font-size: inherit;
  font-family: var(--tokens__font-family__lato);
}
