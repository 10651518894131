:root {
  @include MQ-Above(medium) {
    --gdpr-banner__columns: 3fr 1fr;
  }
}

.gdpr-banner {
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: var(--gdpr-banner__columns, auto);
  grid-gap: 1rem;
  z-index: 35;
  background: black;
  color: white;
  font-size: 0.9rem;
  width: 100%;

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;

    &__decline {
      text-decoration: underline;
    }

    button {
      text-align: center;
      color: white;
    }
  }
}

// Only stick the banner to the top of the screen
// if the screen is taller than 600px
// (this accounts both for small viewports in portrait mode,
// and for larger viewports with high levels of zoom).
@media screen and (min-height: 600px) {
  .gdpr-banner {
    position: fixed;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}
