@supports (-webkit-appearance: none) {
  // make svgs on safari work properly with max width
  svg {
    width: 100%;
  }
}

// Only show outline when user is tabbing
body:not(.user-is-tabbing) *:focus {
  outline: none;
}
