.animation__fade-in-appear {
  opacity: 0;
}

.animation__fade-in-enter {
  opacity: 0;
}

.animation__fade-in-enter-done {
  opacity: 1;
}

.animation__fade-in-exit {
  opacity: 1;
}

.animation__fade-in-exit-active {
  opacity: 0;
}
