:root {
  /* Columns */
  --grid__columns--two: auto;
  --grid__columns--three: repeat(2, 1fr);

  @include MQ-Between(small, medium) {
    --grid__columns--three: repeat(2, 1fr);
  }

  @include MQ-Above(medium) {
    --grid__columns--two: repeat(2, 1fr);
    --grid__columns--three: repeat(3, 1fr);
    --grid-gap: 4rem;
    --grid-gap-large: 6rem;
  }
}

.grid {
  display: grid;
  grid-gap: var(--grid-gap, 2rem);

  // Columns
  &--columns-three {
    grid-template-columns: var(--grid__columns--three);
  }

  &--columns-two {
    grid-template-columns: var(--grid__columns--two);
  }

  // Alignment
  &--align-center {
    align-items: center;
  }

  // Spacing
  &--gap-large {
    grid-gap: var(--grid-gap-large, 2rem);
  }
}
